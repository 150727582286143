
<template>
    <div class="background-body">
        <!-- 后台按钮 -->
        <el-button type="warning" class="btn" plain @click="jumpLogin"
        >政府管理入口
        </el-button
        >
        <!-- 头  -->
        <div class="background-container">
            <span class="title">江苏省企业知识产权质押融资需求信息采集表</span>
        </div>
        <!-- 企业基本情况 -->
        <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            class="demo-ruleForm"
        >
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <span>一、企业基本情况</span>
                </div>
                <el-form-item label="企业名称" prop="companyName"  :label-width="labelWidth1">
                    <el-input v-model="ruleForm.companyName"></el-input>
                </el-form-item>
                <el-form-item
                    label="企业信用代码"
                    prop="companyUnifiedsocialcreditcode"
                    :label-width="labelWidth1"
                >
                    <el-input
                        v-model="ruleForm.companyUnifiedsocialcreditcode"
                    ></el-input>
                </el-form-item>
                <el-form-item label="联系人" prop="companyContact"  :label-width="labelWidth1">
                    <el-input v-model="ruleForm.companyContact"></el-input>
                </el-form-item>
                <el-form-item
                    label="职务"
                    prop="companyContactPosition"
                    :label-width="labelWidth1"
                >
                    <el-input v-model="ruleForm.companyContactPosition"></el-input>
                </el-form-item>
                <el-form-item
                    label="电话/手机"
                    prop="companyContactPhone"
                    :label-width="labelWidth1"
                >
                    <el-input v-model="ruleForm.companyContactPhone"></el-input>
                </el-form-item>
                <el-form-item label="通信地址" prop="companyCity"  :label-width="labelWidth1">

                    <el-select v-model="ruleForm.companyProvince" disabled placeholder="请选择"
                               style="margin-right: 20px"
                    >
                        <el-option
                            v-for="item in companyProvince"
                            :key="item.id"
                            :label="item.name"
                            :value="item.name">
                        </el-option>
                    </el-select>

                    <el-select @change="cityChange" v-model="ruleForm.companyCity" placeholder="请选择">
                        <el-option
                            v-for="item in companyCity"
                            :key="item.id"
                            :label="item.name"
                            :value="item.name"
                        >
                        </el-option>
                    </el-select>

                    <el-select
                        v-model="ruleForm.companyDistrict"
                        collapse-tags
                        style="margin-left: 20px"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in companyDistrict"
                            :key="item.id"
                            :label="item.name"
                            :value="item.name"
                        >
                        </el-option>
                    </el-select>
                    <!-- <el-input v-model="ruleForm.company_city"></el-input> -->
                </el-form-item>
                <el-form-item
                    label="所属行业"
                    prop="companyIndustry"
                    :label-width="labelWidth1"
                >
                    <div class="div-radio-group">
                        <el-radio-group
                            v-model="ruleForm.companyIndustry"
                            class="radio-group-style"
                        >
                            <el-radio label="电子信息">电子信息</el-radio>
                            <el-radio label="新材料">新材料</el-radio>
                            <el-radio label="光机电一体机">光机电一体机</el-radio>
                            <el-radio label="生物制药">生物制药</el-radio>
                            <el-radio label="新能源与高效节能">新能源与高效节能</el-radio>
                            <el-radio label="高技术服务业">高技术服务业</el-radio>
                            <el-radio label="农林牧渔">农林牧渔</el-radio>
                            <el-radio label="其他">其他</el-radio>
                        </el-radio-group>
                    </div>
                </el-form-item>
                <el-form-item
                    label="军民融合企业"
                    prop="companyMilitaryCivilian"
                    :label-width="labelWidth1"
                >
                    <div class="div-radio-group">
                        <el-radio-group
                            v-model="ruleForm.companyMilitaryCivilian"
                            class="radio-group-style"
                        >
                            <el-radio label="是">是</el-radio>
                            <el-radio label="否">否</el-radio>
                        </el-radio-group
                        >
                    </div>
                </el-form-item>
                <el-form-item
                    label="文化企业"
                    prop="companyCulture"
                    :label-width="labelWidth1"
                >
                    <div class="div-radio-group">
                        <el-radio-group
                            v-model="ruleForm.companyCulture"
                            class="radio-group-style"
                        >
                            <el-radio label="是">是</el-radio>
                            <el-radio label="否">否</el-radio>
                        </el-radio-group
                        >
                    </div>
                </el-form-item>
                <el-form-item
                    label="主要产品/服务阶段"
                    prop="companyCoreproductStage"
                    :label-width="labelWidth1"
                >
                    <div class="div-radio-group">
                        <el-radio-group
                            v-model="ruleForm.companyCoreproductStage"
                            class="radio-group-style"
                        >
                            <el-radio label="研制">研制</el-radio>
                            <el-radio label="试生产">试生产</el-radio>
                            <el-radio label="小批量">小批量</el-radio>
                            <el-radio label="批量">批量</el-radio>
                            <el-radio label="其他">其他</el-radio>
                        </el-radio-group>
                    </div>
                </el-form-item>
            </el-card>
            <!-- 企业拥有知识产权情况（2020年度） -->

            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <span>二、企业拥有知识产权情况（{{ toYear }}）</span>
                </div>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item
                            label="专利申请量"
                            prop="companyPatentApplication"
                            label-width="130px"
                        >
                            <el-input
                                v-model="ruleForm.companyPatentApplication"
                                onkeyup="this.value=this.value.replace(/[^\-?\d.]/g,'')"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">

                        <el-form-item
                            label="专利授权量"
                            prop="companyPatentAuthorization"
                            label-width="170px"
                        >
                            <el-input
                                v-model="ruleForm.companyPatentAuthorization"
                                onkeyup="this.value=this.value.replace(/[^\-?\d.]/g,'')"

                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item
                            label="有效专利量"
                            prop="companyPatentEffective"
                            label-width="130px"
                        >
                            <el-input
                                v-model="ruleForm.companyPatentEffective"
                                onkeyup="this.value=this.value.replace(/[^\-?\d.]/g,'')"

                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            label="商标拥有数量"
                            prop="companyBrand"
                            label-width="170px"
                        >
                            <el-input
                                v-model="ruleForm.companyBrand"
                                onkeyup="this.value=this.value.replace(/[^\-?\d.]/g,'')"

                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item
                            label="软件著作权数量"
                            prop="companySoftware"
                            label-width="130px"
                        >
                            <el-input v-model="ruleForm.companySoftware"
                                      onkeyup="this.value=this.value.replace(/[^\-?\d.]/g,'')"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            label="集成电路布图设计数量"
                            prop="companyIC"
                            label-width="170px"
                        >
                            <el-input v-model="ruleForm.companyIC"
                                      onkeyup="this.value=this.value.replace(/[^\-?\d.]/g,'')"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-card>
            <!-- 融资需求 -->
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <span>三、融资需求</span>
                </div>
                <el-form-item label="拟融资额(万元)" prop="loanAmount"  :label-width="labelWidth2">
                    <el-input v-model="ruleForm.loanAmount"
                    >
                        <template slot="append">万元</template>
                    </el-input
                    >
                </el-form-item>
                <el-form-item label="期望贷款期限" prop="loanTimeLimit"  :label-width="labelWidth2">
                    <div class="div-radio-group">
                        <el-radio-group
                            v-model="ruleForm.loanTimeLimit"
                            class="radio-group-style"
                        >
                            <el-radio label="3个月以下">3个月以下</el-radio>
                            <el-radio label="3-6个月">3-6个月</el-radio>
                            <el-radio label="6个月-1年">6个月-1年</el-radio>
                            <el-radio label="1年-2年">1年-2年</el-radio>
                        </el-radio-group>
                    </div>
                </el-form-item>
                <el-form-item label="拟合作银行(可填写多个)" prop="loanBank"  :label-width="labelWidth2">
                    <el-input v-model="ruleForm.loanBank"></el-input>
                </el-form-item>
                <el-form-item label="能够接受的最高融资成本" prop="loanInterestRate"  :label-width="labelWidth2">
                    <div class="div-radio-group">
                        <el-radio-group
                            v-model="ruleForm.loanInterestRate"
                            class="radio-group-style"
                        >
                            <el-radio label="基准利率">基准利率</el-radio>
                            <el-radio label="5%-6%">5%-6%</el-radio>
                            <el-radio label="6%-7%">6%-7%</el-radio>
                            <el-radio label="7%-8%">7%-8%</el-radio>
                            <el-radio label="8%-9%">8%-9%</el-radio>
                            <el-radio label="9%-10%">9%-10%</el-radio>
                            <el-radio label="10%以上">10%以上</el-radio>
                        </el-radio-group>
                    </div>
                </el-form-item>
                <el-form-item label="原有融资渠道" prop="financingChannel"  :label-width="labelWidth2">
                    <div class="div-radio-group">
                        <el-radio-group
                            v-model="ruleForm.financingChannel"
                            class="radio-group-style"
                        >
                            <el-radio label="无">无</el-radio>
                            <el-radio label="银行直接贷款">银行直接贷款</el-radio>
                            <el-radio label="风投基金">风投基金</el-radio>
                            <el-radio label="民间借贷">民间借贷</el-radio>
                            <el-radio label="企业拆借">企业拆借</el-radio>
                            <el-radio label="政府资金">政府资金</el-radio>
                            <el-radio label="资金">资金</el-radio>
                        </el-radio-group>
                    </div>
                </el-form-item>

                <el-form-item label="融资目的(可多选)" prop="loanPurposeList" :label-width="labelWidth2">
                    <div class="div-radio-group">
                        <el-checkbox-group v-model="ruleForm.loanPurposeList">
                            <el-checkbox
                                v-for="data in loanPurposes"
                                :label="data.key"
                                :key="data.key"
                                class="radio-group-style"
                            >{{data.key}}</el-checkbox>
                        </el-checkbox-group>
                    </div>
                </el-form-item>
                <el-form-item
                    label="是否同意将上述信息提供给金融机构"
                    prop="loanFinancialInstitution"
                    :label-width="labelWidth2"
                >
                    <div class="div-radio-group">
                        <el-radio-group
                            v-model="ruleForm.loanFinancialInstitution"
                            class="radio-group-style"
                        >
                            <el-radio label="愿意">愿意</el-radio>
                            <el-radio label="不愿意">不愿意</el-radio>
                        </el-radio-group>
                    </div>
                </el-form-item>
            </el-card>
            <!-- 对知识产权质押融资的政策建议-->
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <span>四、对知识产权质押融资的政策建议</span>
                </div>
                <el-form-item prop="suggestion">
                    <el-input
                        type="textarea"
                        :rows="5"
                        v-model="ruleForm.suggestion"
                    >
                    </el-input>
                </el-form-item>

            </el-card>

            <el-button class="submit-btn" @click="formSubmit('ruleForm')"
            ><span class="submit-title">确定并提交</span></el-button
            >
        </el-form>
    <a href="https://beian.miit.gov.cn" style="display: flex;justify-content: center;" target="_blank">苏ICP备18046134号-1</a>

    </div>
    <!-- 提交按钮 -->
</template>

<script>
import {isSocialCode} from "../utils/validate";

export default {
    data() {
        return {
            ruleForm:
                {
                    companyName: "",
                    companyUnifiedsocialcreditcode: "",
                    companyContact: "",
                    companyContactPosition: "",
                    companyContactPhone: "",
                    companyProvince: "江苏省",
                    companyCity: "",
                    companyIndustry: "",
                    companyMilitaryCivilian: "",
                    companyCulture: "",
                    companyCoreproductStage: "",
                    companyPatentApplication: "",
                    companyPatentAuthorization: "",
                    companyPatentEffective: "",
                    companyBrand: "",
                    companySoftware: "",
                    companyIC: "",
                    loanAmount: "",
                    loanTimeLimit: "",
                    loanBank: "",
                    loanInterestRate: "",
                    financingChannel: "",
                    loanPurposeList: [],
                    loanPurpose: [],
                    loanFinancialInstitution: "",
                    suggestion: ""
                },

            loanPurposes: [
                {key: "扩大经营规模"},
                {key: "扩充周转资金"},
                {key: "归还到期贷款"},
                {key: "固定资产投资"},
                {key: "技巧项目"},
                {key: "投资新项目"},
                {key: "创办新企业"},
                {key: "其他"},
            ],
            companyCity: [],
            companyDistrict: [],
            companyProvince: [],
            toYear: 2021,
            labelWidth1:'140px',
            labelWidth2:'250px',
            rules: {
                companyName: [
                    {required: true, message: '请输入企业名称', trigger: 'blur'},
                ],
                companyUnifiedsocialcreditcode: [
                    {
                        trigger: 'blur', validator: (rule, value, callback) => {
                            if (value.length <= 0) {
                                callback(new Error('请输入信用代码'))
                            } else {
                                if (!isSocialCode(value)) {
                                    callback(new Error('请输入正确的信用代码'))
                                } else {
                                    callback()
                                }
                            }
                        }, required: true
                    },
                ],
                companyContact: [
                    {required: true, message: '请输入联系人', trigger: 'blur'},
                ],
                companyContactPosition: [
                    {required: true, message: '请输入职务', trigger: 'blur'},
                ],
                companyContactPhone: [
                    {required: true, message: '请输入电话/手机', trigger: 'blur'},
                ],
                companyCity: [
                    {required: true, message: '请输入通信地址', trigger: ['blur', 'change']},
                ],
                companyDistrict: [
                    {required: true, message: '请输入通信地址', trigger: ['blur', 'change']},
                ],
                companyIndustry: [
                    {required: true, message: '请输入所属行业', trigger: ['blur', 'change']},
                ],
                companyMilitaryCivilian: [
                    {required: true, message: '请输入军民融合企业', trigger: ['blur', 'change']},
                ],
                companyCulture: [
                    {required: true, message: '请输入文化企业', trigger: ['blur', 'change']},
                ],
                companyCoreproductStage: [
                    {required: true, message: '请输入主要产品/服务阶段', trigger: ['blur', 'change']},
                ],
                companyPatentApplication: [
                    {required: true, message: '请输入专利申请量', trigger: 'blur'},
                ],
                companyPatentAuthorization: [
                    {required: true, message: '请输入专利授权量', trigger: 'blur'},
                ],
                companyPatentEffective: [
                    {required: true, message: '请输入有效专利量', trigger: 'blur'},
                ],
                companyBrand: [
                    {required: true, message: '请输入商标拥有数量', trigger: 'blur'},
                ],
                companySoftware: [
                    {required: true, message: '请输入软件著作权数量', trigger: 'blur'},
                ],
                companyIC: [
                    {required: true, message: '请输入集成电路布图设计数量', trigger: 'blur'},
                ],
                loanAmount: [
                    {required: true, message: '请输入拟融资额', trigger: 'blur'},
                ],
                loanTimeLimit: [
                    {required: true, message: '请输入期望贷款期限', trigger: ['blur', 'change']},
                ],
                loanBank: [{required: true, message: '请输入拟合作银行', trigger: ['blur', 'change']},
                ],
                financingChannel: [
                    {required: true, message: '请输入原有融资渠道', trigger: ['blur', 'change']},
                ],
                loanInterestRate: [
                    {required: true, message: '请输入最高融资成本', trigger: ['blur', 'change']},
                ],
                loanPurposeList: [{
                    trigger:  ['blur','change'], validator: (rule, value, callback) => {
                        if (value.length <= 0) {
                            callback(new Error('请选择融资目的'))
                        } else {
                            callback()
                        }
                    }
                }],

                loanFinancialInstitution: [
                    {
                        required: true, message: '请输入是否同意将上述信息提供给金融机构', trigger: ['blur', 'change']
                    },
                ],
                suggestion: [
                    {
                        required: true, trigger: 'blur', validator: (rule, value, callback) => {
                            if (value.length <= 0) {
                                callback(new Error('请输入对知识产权质押融资的政策建议'))
                            } else {
                                callback()
                            }
                        }
                    },
                ]
            }
        };
    },
    created() {
        this.getYear();
        this.getCity();
    },
    methods: {
        getYear() {
            var myDate = new Date();
            this.toYear = myDate.getFullYear() - 1;
        },
        jumpLogin() {
            this.$router.push("/login");
        },
        getCity() {
            this.companyCity = []
            this.getRequest("/config/addr?province=" + this.ruleForm.companyProvince).then((res) => {
                this.companyCity = res.data
            }).catch((err) => {
                return this.$message.error("获取所在市失败")
            })
        },
        cityChange(value) {
            this.companyCity.forEach((item) => {
                this.companyDistrict = []
                if (item['name'] === value) {
                    this.getRequest("/config/addr?cityId=" + item["id"]).then((res) => {
                        this.companyDistrict = res.data
                    }).catch((err) => {
                        return this.$message.error("获取区数据失败")
                    })
                }
            })
        },
        formSubmit(formName) {
            // if (this.loanPurposeList.length === 0) {
            //     this.ruleForm.loanPurpose = this.loanPurposeList[0]
            // } else if (this.loanPurposeList.length > 0) {
            //     this.ruleForm.loanPurpose = this.loanPurposeList.join(",")
            // } else {
            //     this.ruleForm.loanPurpose = ""
            // }
            this.ruleForm.loanPurpose = this.ruleForm.loanPurposeList.join(",")
            console.log(this.ruleForm)
            this.$refs[formName].validate((val) => {
                if (val) {

                    this.postRequest('/pledge/form', this.ruleForm).then((res) => {
                        this.$message.success(res.message)
                        this.$refs[formName].resetFields()
                    }).catch((err) => {
                        this.$message.error("数据新增失败")
                    })
                }

            })
        }
    },

};
</script>

<style scoped>

    .btn {
        margin-top: 20px;
        margin-left: 20px;
    }

    .title {
        color: #fff;
        font-size: 26px;
        line-height: 1.7;
        height: 130px;
        display: flex;
        align-items: center;
        /* text-align: center; */
        justify-content: center;
    }

    .background-container {
        background-image: linear-gradient(
            90deg,
            rgb(236, 78, 30),
            rgba(252, 174, 31, 0.9),
            rgb(236, 78, 30)
        );

        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .box-card {
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        border: 1px solid #ebeef5;
        background-color: #fff;
        color: #303133;
    }

    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }

    .clearfix:after {
        clear: both;
    }

    .div-radio-group {
        border: 1px solid #dcdfe6;
        border-radius: 5px;
        /* margin-left: 50px; */
    }

    .radio-group-style {
        margin-left: 20px;
    }

    .submit-btn {
        margin-top: 20px;
        margin-bottom: 20px;

        margin-left: 20px;
        margin-right: 20px;
        background-image: linear-gradient(
            90deg,
            rgb(236, 78, 30),
            rgba(252, 174, 31, 0.9),
            rgb(236, 78, 30)
        );
        width: 98%;
        height: 60px;
    }

    .submit-title {
        font-size: 26px;
        color: white;
    }

    .background-body {
        margin-left: 20px;
        margin-right: 20px;
    }

</style>
